import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";
import axios from "axios";
import "./PoFileLoadingStatus.css";
import Modal from "react-bootstrap/Modal";

function CustomSpinner() {
  return (
    <Spinner animation="border" role="status" id="spinner">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  );
}

function PoFileLoadingStatus() {
  const [loading, setLoading] = useState(true);
  const [remaining_file_count, setRemainingFilesCount] = useState(0);

  const [viewModal, setViewModal] = useState(false);
  const [POLogsData, setPOLogsData] = useState([]);

  useEffect(() => {
    async function fetch_data() {
      await fetchCurrentFileLoadingStatus();
    }
    fetch_data();
  }, []);

  const fetchCurrentFileLoadingStatus = async () => {
    setLoading(true);
    const res = await axios.get("/admin/getCurrentPoFileLoadingStatus");
    // console.log(res)
    const file_count = res.data.remaining_files;
    setRemainingFilesCount(file_count);
    setLoading(false);
  };

  const refreshHandler = async () => {
    await fetchCurrentFileLoadingStatus();
  };

  const handlePOHistory = async () => {
    setPOLogsData([]); // Clear old data to prevent showing stale data
    setViewModal(true); // Open the modal
    
    try {
      const poLogs = await axios.get(
        process.env.REACT_APP_API_LINK + "/api/v1/admin/getPoFileLoadLogs"
      );
      setPOLogsData(poLogs.data.data.reverse());
    } catch (error) {
      console.error("Error fetching PO logs:", error);
    }
  };

  const handleClose = () => setViewModal(false);
  return (
    <div id="main">
      <div id="container_">
        <div>File loading status</div>
        <div id="Spinner">
          {loading ? <CustomSpinner /> : remaining_file_count + " remaining.."}
        </div>
        <Navbar.Toggle />
      </div>
      <div className="justify-content-end" id="btn_both">
        <Button variant="success" onClick={refreshHandler}>
          Refresh
        </Button>
        <Button variant="primary" onClick={handlePOHistory}>
          History
        </Button>

        <Modal
          show={viewModal}
          onHide={handleClose}
          animation={false}
          scrollable={true}
        >
          <Modal.Header closeButton>
            <Modal.Title>PO LOGS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {POLogsData &&
              POLogsData.map((logData, index) => {
                const logObject = {
                  id: logData.id,
                  fileName: logData.fileName,
                  status: logData.status,
                  remarks: logData.remarks,
                  createdAt: logData.createdAt,
                  updatedAt: logData.updatedAt,
                };
                // console.log(`Log Data ${index + 1}:`, logObject);
                return (
                  <div
                    key={index}
                    style={{
                      marginBottom: "1rem",
                      borderBottom: "1px solid #ccc",
                    }}
                  >
                    <h5>Log {index + 1}</h5>
                    <p style={{margin: 0}}>ID: {logObject.id}</p>
                    <p style={{margin: 0}}>File Name: {logObject.fileName}</p>
                    <p style={{margin: 0}}>Status: {logObject.status}</p>
                    <p style={{margin: 0}}>Remarks: {logObject.remarks}</p>
                    <p style={{margin: 0}}>Created At: {logObject.createdAt}</p>
                    <p style={{margin: 0}}>Updated At: {logObject.updatedAt}</p>
                  </div>
                );
              })}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default PoFileLoadingStatus;

// SocketContext.js
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import io from "socket.io-client";

const SocketContext = createContext();

export const useSocketContext = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [maintenancetime, setMaintenanceTime] = useState(null);
  const [timeDuration, setTimeDuration] = useState(null);
  const socketRef = useRef(null);
  const url = process.env.REACT_APP_API_LINK;

  useEffect(() => {
    socketRef.current = io(url, {
      auth: {
        token: localStorage.getItem("token"),
      },
      withCredentials: true,
    });

    socketRef.current.on("connect", () => {
      setIsConnected(true);
    });

    socketRef.current.on("disconnect", () => {
      setIsConnected(false);
    });

    socketRef.current.on("connect_error", (err) => {
      console.error("Connection Error:", err);
      setIsConnected(false);
    });

    // socketRef.current.on('maintenance', (time) => {
    //   console.log('time>>>>', time);
    //   setMaintenanceTime(time.maintenance_time)
    // })

    // socketRef.current.on('maintenance' , (time_duration ) => {
    //   console.log('time duration', time_duration.time_duration);
    //   setTimeDuration(time_duration.time_duration)
    // })

    socketRef.current.on("maintenance", (maintenance) => {
      // console.log("maintenance data:", maintenance);
      setMaintenanceTime(maintenance.maintenance_time);
      setTimeDuration(maintenance.time_duration);
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, [url]);

  const emit = (eventName, data) => {
    if (socketRef.current) {
      socketRef.current.emit(eventName, data);
    }
  };

  const on = (eventName, callback) => {
    if (socketRef.current) {
      socketRef.current.on(eventName, callback);

      return () => {
        if (socketRef.current) {
          socketRef.current.off(eventName, callback);
        }
      };
    }
  };

  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.disconnect();
    }
  };

  return (
    <SocketContext.Provider
      value={{
        isConnected,
        emit,
        on,
        disconnect,
        maintenancetime,
        timeDuration,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

import React, { useContext, useState } from "react";
import { Modal, Button, Container } from "react-bootstrap";
import Context from "../../context/context";
import nullify from "../../utility/nullify";
import axios from "axios";
import checkRatio from "../../utility/checkRatio";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
function ConfirmationWindow({ isShow, setIsShow, grandTotal, type }) {
  // console.log("typeee", type);
  const [btnLoading, setBtnLoading] = useState(false);

  const navigate = useNavigate();
  const ctx = useContext(Context);
  const validate = (LOT, userInput) => {
    if (LOT === "000") return true;
    var result = axios
      .get(process.env.REACT_APP_API_LINK + "/api/v1/lot/getOne?LOT=" + LOT, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        var lot = response.data.lot;
        var ratioArray = Object.values(lot).map((x) => parseInt(x));
        var inputArray = Object.values(userInput).map((x) =>
          x === "" ? 0 : parseInt(x)
        );

        return checkRatio(inputArray, ratioArray);
      })
      .catch((error) => {
        // console.log(error)
      });
    return result;
  };

  const handleSubmit = async () => {
    setBtnLoading(true);
    var errorAt = [];
    const keys = Object.keys(ctx.userEntries);
    for (const key of keys) {
      var entry = ctx.userEntries[key];
      const validationResult = await validate(ctx.poData[key].LOT, entry);
      if (validationResult === false) {
        errorAt.push(key);
      }
    }
    if (errorAt.length !== 0) return alert(errorAt);
    axios
      .post(
        process.env.REACT_APP_API_LINK + "/api/v1/entry/new",
        {
          type: type,
          userEntries: ctx.userEntries,
          poEntries: ctx.poEntries,
          weightData: ctx.weightData,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then(async (err) => {
        const res = await axios.post(
          "/po/getLockedPurchaseOrders",
          {
            POIDs: ctx.selectedCheckboxes,
            entry: true,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        // console.log("sconnnected 1");

        if (res.data.poData.length === 0) {
          ctx.setPoData([]);
          ctx.setWeightData({ numOfBoxes: 1 });
          ctx.setSelectedCheckboxes([]);
          ctx.setSelectedPo("");
          ctx.setFilterClicked(false);
          // console.log("connect 2");
          await axios
            .post("/po/getFilteredPurchaseOrders", {
              ...ctx.id,
              open: ctx.openCheck,
              processed: ctx.procesCheck,
              pageNo: 0,
              docNo: 30,
              order: [
                ["FACT", "ASC"],
                ["SUP", "ASC"],
                ["AGNT", "ASC"],
                ["PO", "ASC"],
                ["ETA", "ASC"],
              ],
            })
            .then((res) => {
              ctx.setPoDataDSP(res.data.poData);
              // console.log("resaaaa", res.data.poData);
            });

          ctx.setPoData(res.data.poData);
          // ctx.setLastTo(res.data.lastValueOfTo);
          ctx.setUserEntries({});
          ctx.setPOEntries({});
          // ctx.setId("")
          localStorage.removeItem("PO");
          return navigate("/boxDetails");
        }

        axios
          .post("/po/getFilteredPurchaseOrders", {
            ...ctx.id,
            open: ctx.openCheck,
            processed: ctx.procesCheck,
            pageNo: 0,
            docNo: 30,
            order: [
              ["FACT", "ASC"],
              ["SUP", "ASC"],
              ["AGNT", "ASC"],
              ["PO", "ASC"],
              ["ETA", "ASC"],
            ],
          })
          .then((res) => {
            ctx.setPoDataDSP(res.data.poData);
            // console.log("resssaaaa", res.data.poData);
          });

        ctx.setPoData(res.data.poData);
        ctx.setLastTo(res.data.lastValueOfTo);
        ctx.setUserEntries({});
        ctx.setPOEntries({});
        //console.log('weightdata from confimation: ', ctx.weightData)
        ctx.setWeightData(ctx.weightData);
        toast.success("Success");
        setIsShow(false);
        // DND Logic to reload page without reloading
        navigate("/");
        setTimeout(() => {
          navigate("/orders");
        }, 0.5);
      })
      .catch((err) => {
        // console.log(err.response);
        alert("Something is Wrong, please check ");
        setBtnLoading(false);
      });
  };

  //console.log("weight data", ctx.weightData);
  //console.log('user input',ctx.userEntries)

  return (
    <Modal show={isShow} className=".modal-content">
      <Modal.Header
        closeButton
        onClick={() => {
          setIsShow(false);
        }}
      >
        <Modal.Title className="modal-title">
          <h6>Box Entries</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4>{"Please verify below data of a Box"}</h4>
        <div className="d-flex "></div>
        <div className="">
          <span className="fw-bold">BoxGroup </span>
          <span style={{ marginLeft: "62px" }}>
            :&nbsp;&nbsp;{ctx?.weightData.BoxGroup || " "}
          </span>
        </div>
        <div className="d-flex">
          <span className="fw-bold">PO# </span>
          <span style={{ marginLeft: "110px" }}>
            :&nbsp;&nbsp;{Object.values(ctx?.poData)[0]?.PO}
          </span>
        </div>
        <div className="d-flex">
          <span className="fw-bold">Number of Boxes</span>
          <span style={{ marginLeft: "11px" }}>
            :&nbsp;&nbsp;
            {`${ctx.weightData.numOfBoxes} (${ctx.weightData.From}-${ctx.weightData.To})`}
          </span>
        </div>
        <div className="d-flex ">
          <span className="fw-bold">Total_Qty </span>{" "}
          <span style={{ marginLeft: "70px" }}>:&nbsp;&nbsp;{grandTotal}</span>
        </div>
      </Modal.Body>
      <Modal.Body>
        <Container>
          <table className="table table-bordered table-sm ">
            <thead>
              <tr className="table-warning">
                <th>Sea</th>
                <th>Sty</th>
                <th>Lot</th>
                <th>Clr</th>
                <th>Dim</th>
                <th>Tot_Qty</th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[0] : "SZ01"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[1] : "SZ02"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[2] : "SZ03"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[3] : "SZ04"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[4] : "SZ05"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[5] : "SZ06"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[6] : "SZ07"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[7] : "SZ08"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[8] : "SZ09"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[9] : "SZ10"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[10] : "SZ11"}
                </th>
                <th>
                  {ctx.newHeader.length !== 0 ? ctx.newHeader[11] : "SZ12"}
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(ctx.userEntries).map((key, index) => {
                return (
                  <>
                    <tr className="table-active" key={key}>
                      <td>{ctx.poData[parseInt(key)]?.SEA}</td>
                      <td>{ctx.poData[parseInt(key)]?.STY}</td>
                      <td>{ctx.poData[parseInt(key)]?.LOT}</td>
                      <td>{ctx.poData[parseInt(key)]?.CLR}</td>
                      <td>{ctx.poData[parseInt(key)]?.DIM}</td>
                      <td>
                        {Object.values(ctx.userEntries[key]).reduce((i, n) => {
                          var first = i === "" ? 0 : i;
                          var second = n === "" ? 0 : n;
                          return first + second;
                        })}
                      </td>
                      <td>{nullify(ctx.userEntries[key].SZ01)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ02)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ03)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ04)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ05)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ06)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ07)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ08)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ09)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ10)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ11)}</td>
                      <td>{nullify(ctx.userEntries[key].SZ12)}</td>
                    </tr>
                    <tr style={{height: '20px'}}><td style={{border: 'none'}}/></tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ textAlign: "center", width: "100px" }}
          variant="danger"
          onClick={() => {
            setIsShow(false);
          }}
        >
          Cancel
        </Button>

        {btnLoading ? (
          <Button
            variant="dark"
            style={{ textAlign: "center", width: "100px" }}
            onClick={() => {}}
          >
            <Spinner size="sm" />
          </Button>
        ) : (
          <Button
            variant="dark"
            style={{ textAlign: "center", width: "100px" }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Continue
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmationWindow;

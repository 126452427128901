import React, { createContext, useContext, useState, useEffect } from "react";
import { useSocketContext } from "./socketContext";

const MaintenanceContext = createContext();

export const useMaintenanceContext = () => {
  return useContext(MaintenanceContext);
};

export const MaintenanceProvider = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { on } = useSocketContext();

  useEffect(() => {
    // console.log("Setting up maintenance listener");
    const maintenanceListener = (time) => {
      // console.log("Maintenance time received:", time);
      if (Date.now() < time.maintenance_time) {
        setIsModalOpen(true);
      }
    };

    on("maintenance", maintenanceListener);

    // return () => {
    //   console.log("Cleaning up maintenance listener");
    //   on("maintenance", null);
    // };
  }, [on]);

  return (
    <MaintenanceContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      {children}
    </MaintenanceContext.Provider>
  );
};

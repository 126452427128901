import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSocketContext } from "../context/socketContext";
import context from "../context/context";
import { useMaintenanceContext } from "../context/MaintenanceContext";

function formatAMPM(date) {
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12; // Convert to 12-hour format
  hours = hours ? hours : 12; // 0 becomes 12
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")} ${ampm}`;
  return formattedTime;
}

function MaintenanceModal({ open }) {
  const ctx = useContext(context);

  const { maintenancetime, timeDuration } = useSocketContext();

  const { isModalOpen, setIsModalOpen } = useMaintenanceContext();
  let date = new Date(parseInt(maintenancetime));
  // const setDate = date.toString();

  const formattedDate = `${date.toLocaleString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  })} ${formatAMPM(date)} ${
    date.toTimeString().match(/GMT[^\(]+\([^\)]+\)/)
      ? date.toTimeString().match(/GMT[^\(]+\([^\)]+\)/)[0]
      : ""
  }`;

  // console.log(formattedDate);

  //duration in mins
  const timeMin = Math.floor((timeDuration / 1000 / 60) << 0);
  // console.log(timeMin );

  return (
    <Modal
      show={isModalOpen}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Scheduled Maintenance
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Our services are scheduled for maintenance on{" "}
          <strong>{formattedDate}</strong> for a duration of{" "}
          <strong>{timeMin} minutes</strong> . Please plan accordingly, as
          service availability may be impacted during this time.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => setIsModalOpen(false)}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default MaintenanceModal;

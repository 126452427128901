/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import Context from "../../context/context";
import ConfirmationWindow from "./ConfirmationWindow";
import { Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-hot-toast";
import ErrosToast from "../../components/ErrosToast";
//import Loader from "../../components/Loader";

function calculateMahatotal(mahaObject) {
  const keys = Object.keys(mahaObject);
  var mahaSum = 0;
  for (let key of keys) {
    let tempSum = Object.values(mahaObject[key]).reduce((i, n) => {
      var first = i === "" ? 0 : i;
      var second = n === "" ? 0 : n;
      return first + second;
    });
    mahaSum += tempSum;
  }

  return mahaSum;
}
const Weights = ({ totInputBoxs, lotRatios }) => {
  const ctx = useContext(Context);
  // console.log("number of boxessss", ctx.weightData.numOfBoxes);
  const [isShow, setIsShow] = useState(false);

  const [boxType, setBoxType] = useState([]);
  const [boxGroupSuggestion, setBoxGroupSuggestion] = useState([]);
  const [grandTotal, setGT] = useState(null);

  const [selectedOption, setSelectedOption] = useState("regular");
  // const [overshipValue] = useState(0) // updated overship value

  // console.log('user data', ctx.userData)

  // useEffect(()=> {
  //   axios.post(
  //     process.env.REACT_APP_API_LINK + "/api/v1/po/getLockedPurchaseOrders",
  //     {
  //       POIDs: ctx.selectedCheckboxes,
  //     },
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //       },
  //     }
  //   )
  //   .then((res)=> {
  //     // console.log('locked po data', res.data);
  //     setOvershipValue(res.data.overshipValue)
  //   })
  // },[])

  // console.log('overship', overshipValue)
  // console.log('ctx po data', ctx.poData)

  const compareArraysAndAlert = (ratios, inputdatas) => {
    if (ratios.length !== inputdatas.length) {
      return false;
    }
    for (let i = 0; i < ratios.length; i++) {
      if (ratios[i] !== 0) {
        if (inputdatas[i] % ratios[i] !== 0) {
          ErrosToast(
            `Incorrect lot ratio at position ${
              i + 1
            }. Here you can enter only multiples of Lot ratio ${ratios[i]}`
          );
          return false;
        }
      }
    }
    return true;
  };

  const initModal = (e) => {
    const GT = calculateMahatotal(ctx.userEntries) * ctx.weightData.numOfBoxes;
    setGT(GT);
    if (
      !ctx.weightData.Length ||
      !ctx.weightData.Width ||
      !ctx.weightData.Height ||
      !ctx.weightData.NetWt ||
      !ctx.weightData.GrossWt ||
      !ctx.weightData.From
    ) {
      // if (isEmpty)

      alert(
        `Please Check and fill in fields  Boxes, From , To,Height,Width ,NetWt, GrossWt before submitting.`
      );
      return setIsShow(false);
    }
    if (
      ctx.weightData.From === 0 ||
      ctx.weightData.NetWt === 0 ||
      ctx.weightData.GrossWt === 0 ||
      ctx.weightData.Length === 0 ||
      ctx.weightData.Width === 0 ||
      ctx.weightData.Height === 0
    ) {
      alert(
        " From ,Netwt, GrossWt, Length, Width, Height can not be 0. Please correct!"
      );
      return setIsShow(false);
    }

    //totInputBoxs, lotRatios
    const checkStatus = compareArraysAndAlert(lotRatios, totInputBoxs);
    if (!checkStatus) {
      return;
    }

    if (Object.keys(ctx.userEntries).length === 0) {
      alert("You haven't entered any entries yet");
      return setIsShow(false);
    }

    if (ctx.weightData.numOfBoxes <= 0) {
      alert("Number of boxes cannot be 0 or less");
      return setIsShow(false);
    }
    if (ctx.weightData.From >= ctx.lastTo && selectedOption === "insert") {
      alert(
        `Check the From Field , BoxEntry should smaller than ${ctx.lastTo}`
      );
      return setIsShow(false);
    }

    // if (
    //   ctx.lastBCGeneratedSNo >= ctx.weightData.From &&
    //   selectedOption == "insert"
    // ) {
    //   toast.remove();
    //   ErrosToast(
    //     `You have inserted boxes  in generate barcode boxes, you can insert boxes from ${
    //       ctx.lastBCGeneratedSNo + 1
    //     }`
    //   );
    //   return;
    // }
    // if (ctx.weightData.BoxGroup.length < 5) {
    //   alert("Shipment length should be minimum 5 and maximum 20");
    //   return setIsShow(false);
    // }
    if (GT === 0) {
      return alert("Total cannot be 0");
    }
    // let ind = 0;
    // Object.keys(ctx.userEntries).forEach((key, index) => {
    //   if (
    //     Object.values(ctx.userEntries[key]).reduce((i, n) => {
    //       var first = i === "" ? 0 : i;
    //       var second = n === "" ? 0 : n;
    //       return first + second;
    //     }) *
    //       ctx.weightData.numOfBoxes +
    //       ctx.poData[key].packedQuantity.TOT_QTY * 1 >
    //     ctx.poData[key].TOT_QTY * 1 +
    //       ctx.poData[key].TOT_QTY *
    //         (ctx.overshipValue > 0
    //           ? ctx.overshipValue / 100
    //           : ctx.userData.defaultOvership / 100)
    //   ) {
    //     if (
    //       ctx.poData[key].LOT !== "000" &&
    //       (ctx.overshipValue > 0
    //         ? ctx.overshipValue
    //         : ctx.userData.defaultOvership) >= 0
    //     ) {
    //       return (ind = index + 1);
    //     }
    //   }
    // });

    const { totWithOvership, totPackedWithOvership, totQtyFromUserInput } =
      ctx.totQtyAndPackedWithOvership;

    let ind = 0;

    Object.keys(ctx.userEntries).forEach((key, index) => {
      const totalPackedQuantity =
        Object.values(ctx.userEntries[key]).reduce((i, n) => {
          const first = i === "" ? 0 : i;
          const second = n === "" ? 0 : n;
          return first + second; // Calculate the sum of packed quantities for this key
        }, 0) * ctx.weightData.numOfBoxes; // Multiply by the number of boxes

      const totalAllowedQuantity = ctx.poData[key].TOT_QTY * 1;

      const packedQty = ctx.poData[key].packedQuantity.TOT_QTY;

      if (
        Number(packedQty) + totalPackedQuantity > totalAllowedQuantity ||
        totalPackedQuantity > totalAllowedQuantity
      ) {
        if (
          ctx.poData[key].LOT !== "000" &&
          (ctx.overshipValue > 0
            ? ctx.overshipValue
            : ctx.userData.defaultOvership) >= 0
        ) {
          ind = index + 1; // Update `ind` to the 1-based index
        }
      }
    });
    if (ind) {
      toast.remove();
      return ErrosToast("Value can't be more than total qty");
    }

    let findTrue = ctx.isTotalQtyWithOvership.findIndex(
      (value) => value === true
    );

    if (
      totQtyFromUserInput * ctx.weightData.numOfBoxes + totPackedWithOvership >
      totWithOvership
    ) {
      toast.remove();
      return ErrosToast(
        "The total quantity available is less than the entered Quantity"
      );
    } else {
      findTrue = -1;
    }

    if (findTrue >= 0) {
      ErrosToast(
        `Position ${
          findTrue + 1
        }: The total quantity available is less than the entered Quantity .`
      );
      return setIsShow(false);
    }

    return setIsShow(true);
  };

  useEffect(() => {
    // const strLast = ctx.userData["barcodeRange.last"].toString();
    // const strTo = ctx.userData["barcodeRange.to"].toString();
    // commenting this alert as its not requiredd as we are not generating range here
    // if (
    //   parseInt(ctx.weightData.numOfBoxes) + parseInt(rangeLast) >
    //   parseInt(rangeTo)
    // ) {
    //   alert("Warning: Given number of boxes are exceeding NVE RANGE.");
    // }
  }, [
    ctx.weightData.numOfBoxes,
    ctx.userData["barcodeRange.last"],
    ctx.userData["barcodeRange.to"],
  ]);

  // console.log("weight data", ctx.weightData);
  // console.log("userdata", ctx.poData);
  // console.log("last to", ctx.lastTo);
  // console.log('selected option', selectedOption)

  const handleUserInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    // console.log("namee", name);
    // console.log("value", value);
    ctx.setWeightData({
      ...ctx.weightData,
      PO: ctx.poData[0].PO,
      STY: ctx.poData[0].STY,
    });
    // console.log('weif', ctx.weightData.numOfBoxes)
    if (name === "numOfBoxes") {
      if (selectedOption === "regular") {
        ctx.setWeightData({
          ...ctx.weightData,
          [name]: value.replace(/\D/g, ""),
          From: ctx.weightData.From || ctx.lastTo,
          To:
            value === "" || value === null || value === 0 || isNaN(value)
              ? parseInt(ctx.weightData.From)
              : parseInt(ctx.weightData.From || ctx.lastTo) +
                parseInt(value || 0) -
                1,
        });
      } else if (selectedOption === "insert") {
        // if (selectedOption === "insert" && ctx.weightData.From >= ctx.lastTo) {
        //   toast.remove();
        //   toast.error(`From should be smaller than ${ctx.lastTo + 1}`);
        //   // ctx.setWeightData.From(0);
        //   // ctx.setWeightData.From("");
        //   return;
        // }
        ctx.setWeightData({
          ...ctx.weightData,
          [name]: value.replace(/\D/g, ""),
          From: ctx.weightData.From || 0,
          To: parseInt(ctx.weightData.From) + parseInt(value) - 1,
        });
      }
    } else if (name === "From") {
      const numValue = parseInt(value.replace(/\D/g, ""));

      if (ctx.lastTo >= value && selectedOption === "regular") {
        toast.remove();
        ErrosToast(`It should start from  ${ctx.lastTo + 1}`);
        ctx.setWeightData({
          ...ctx.weightData,
          [name]: numValue || "",
        });
      } else if (selectedOption === "insert") {
        toast.remove();
        if (numValue >= ctx.lastTo) {
          ErrosToast(`From should be smaller than ${ctx.lastTo}`);
        }
        ctx.setWeightData({
          ...ctx.weightData,
          [name]: numValue || 0,
          // Form: ctx.lastTo + 1,
          To: parseInt(value) + parseInt(ctx.weightData.numOfBoxes) - 1 || 0,
        });
      } else {
        toast.remove();
        ctx.setWeightData({
          ...ctx.weightData,
          [name]: numValue,

          To:
            parseInt(ctx.weightData.numOfBoxes || ctx.lastTo) +
              parseInt(value || 0) -
              1 || "",
        });
      }
    } else if (name === "NetWt" || name === "GrossWt") {
      // Allow only numbers and at most one '.'
      const WtValue = value.replace(/[^0-9.]/g, "");
      const singleDecimalValue =
        WtValue.split(".").length > 2
          ? WtValue.substring(0, WtValue.lastIndexOf("."))
          : WtValue;

      var finalValue = singleDecimalValue;
      var splittedValue = singleDecimalValue.split(".");

      // Limit to 3 decimal places
      if (splittedValue[1]?.length > 3)
        finalValue = splittedValue[0] + "." + splittedValue[1].slice(0, 3);

      ctx.setWeightData({
        ...ctx.weightData,
        [name]: finalValue,
      });
    } else if (name === "Length" || name === "Width" || name === "Height") {
      const WtValue = value.replace(/[^\d]/g, "");
      ctx.setWeightData({
        ...ctx.weightData,
        [name]: WtValue,
      });
    } else {
      ctx.setWeightData({
        ...ctx.weightData,
        [name]: e.target.value,
      });
    }
  };
  const isInvalid = (fieldName) => boxType[fieldName] === "";
  // const handleUserInput1 = async (e) => {
  //   const newSearchTerm = e.target.value;
  //   ctx.setWeightData({
  //     ...ctx.weightData,
  //     [e.target.name]: e.target.value,
  //   });
  // };
  // ctx.setWeightData({ ...boxType });

  useEffect(() => {
    axios
      .get(
        process.env.REACT_APP_API_LINK + "/api/v1/admin/getAllBoxMasters",

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        // console.log(res, "ressss");
        setBoxType(res.data.boxMasters);
      })
      .catch((err) => {
        // console.log(err)
      });
  }, []);

  useEffect(() => {
    ctx.setWeightData({
      ...ctx.weightData,
      numOfBoxes: 1,
      From: ctx.lastTo || 0,
      To: ctx.lastTo || 0,
    });
    //console.log("ctx1", ctx);
  }, []);

  // useEffect(() => {
  //   ctx.setWeightData(()=> ({
  //     ...ctx.weightData,
  //   }));
  // }, [ctx.weightData.From, ctx.weightData.To, ctx.weightData.numOfBoxes])

  //console.log("ctx2", ctx);
  const handleInputChange = async (event) => {
    // Get the input value and remove '%' characters
    const rawValue = event.target.value;
    const newSearchTerm = rawValue.replace(/%/g, "").toUpperCase();

    // Update context with the cleaned value
    ctx.setWeightData({
      ...ctx.weightData,
      [event.target.name]: newSearchTerm,
    });

    // Fetch suggestions only if the cleaned value is not empty
    if (newSearchTerm) {
      try {
        const response = await axios.get(
          `/po/searchBoxGroup?searchTerm=${newSearchTerm}`
        );
        setBoxGroupSuggestion(response.data.suggestions);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
      }
    } else {
      setBoxGroupSuggestion([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    ctx.setWeightData({
      ...ctx.weightData,
      BoxGroup: suggestion,
    });
    setBoxGroupSuggestion([]);
  };

  const handleBoxTypeChange = (e) => {
    const boxtypeValue = e.target.value;
    if (boxtypeValue === "") {
      ctx.setWeightData({
        ...ctx.weightData,
        BoxType: "",
        Length: "",
        Width: "",
        Height: "",
      });
    }
    //console.log('prev value',boxtypeValue)
    else if (e.target.value !== null && boxType[boxtypeValue]) {
      ctx.setWeightData({
        ...ctx.weightData,
        // NetWt: boxType[e.target.value].NetWt,
        // GrossWt: boxType[e.target.value].GrossWt,
        Length: boxType[e.target.value].Length,
        Width: boxType[e.target.value].Width,
        Height: boxType[e.target.value].Height,
        BoxType: boxType[e.target.value].type,
      });
    }
    //console.log(boxtypeValue)
  };

  const handleChangeForBoxes = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    // console.log("value of box", value);
    if (value === "regular") {
      ctx.setWeightData({
        ...ctx.weightData,
        numOfBoxes: 1,
        From: ctx.lastTo,
        To: parseInt(ctx.lastTo) + 1 - 1,
      });
    } else if (value === "insert") {
      ctx.setWeightData({
        ...ctx.weightData,
        numOfBoxes: 1,
        From: 1,
        // To: parseInt(ctx.lastTo) + 1 - 1,
        To: 1,
      });
    }
  };

  // console.log("no of boxes", ctx.weightData.numOfBoxes);
  // console.log("", ctx.lastBCGeneratedSNo);
  // console.log("hihi", parseInt(ctx.weightData.From) - 1);

  return (
    <>
      <ConfirmationWindow
        type={selectedOption}
        isShow={isShow}
        setIsShow={setIsShow}
        grandTotal={grandTotal}
      />
      {ctx.isFilter ? (
        <div className="d-flex justify-content-center align-items-center mx-4 ">
          <Form className="">
            <Row>
              <Col>
                <label htmlFor="options" className="pb-2">
                  Select*
                </label>
                <select
                  id="options"
                  name="options"
                  onChange={handleChangeForBoxes}
                  style={{
                    padding: "2px",
                    height: "33px",
                    border: isInvalid("options")
                      ? "3px solid red"
                      : "2px solid black",
                    width: "120px",
                  }}
                  required
                >
                  <option value="regular">Regular Boxes</option>
                  <option
                    value="insert"
                    // disabled={
                    //   ctx.lastBCGeneratedSNo <= 1 && ctx.weightData.From <= 1
                    // }
                    // disabled={
                    //   ctx.lastBCGeneratedSNo >=
                    //   parseInt(ctx.weightData.From) - 1
                    // }
                  >
                    Insert Boxes
                  </option>
                </select>
              </Col>

              {selectedOption === "regular" && (
                <>
                  {" "}
                  <Col>
                    <Form.Group controlId="input1">
                      <Form.Label> Boxes*</Form.Label>
                      <Form.Control
                        autoFocus
                        size="sm"
                        type="text"
                        placeholder="NO.of boxes"
                        maxLength={7}
                        name="numOfBoxes"
                        onChange={handleUserInput}
                        value={ctx.weightData.numOfBoxes}
                        required
                        style={{
                          border: isInvalid("input5") ? "3px solid red" : "",
                          width: "75px",
                        }}
                        // style={{ border: "3px solid #000", width: "75px" }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="input1">
                      <Form.Label>From*</Form.Label>
                      <Form.Control
                        size="sm"
                        autoFocus
                        type="text"
                        title={`It should start from  ${ctx.lastTo} `}
                        placeholder="Starting"
                        maxLength={7}
                        name="From"
                        defaultValue={ctx.lastTo || 0}
                        onChange={handleUserInput}
                        value={ctx.weightData.From}
                        required
                        style={{
                          border: isInvalid("input5")
                            ? "3px solid red"
                            : "2px solid black",
                        }}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="input1">
                      <Form.Label>To*</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        autoFocus
                        placeholder="Ending"
                        // maxLength={7}
                        name="To"
                        onChange={handleUserInput}
                        value={ctx.weightData.To}
                        required
                        style={{
                          border: isInvalid("input5")
                            ? "3px solid red"
                            : "2px solid black",
                        }}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </>
              )}

              {selectedOption === "insert" && (
                <>
                  {" "}
                  <Col>
                    <Form.Group>
                      <Form.Label>InsertBoxes*</Form.Label>
                      <Form.Control
                        autoFocus
                        size="sm"
                        type="text"
                        placeholder="NO.of boxes"
                        maxLength={7}
                        name="numOfBoxes"
                        onChange={handleUserInput}
                        value={ctx.weightData.numOfBoxes}
                        required
                        // style={{
                        //   border: isInvalid("input5") ? "3px solid red" : "",
                        //   width: "75px",
                        // }}
                        // style={{ border: "3px solid #000", width: "75px" }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="input1">
                      <Form.Label> InsertFrom*</Form.Label>
                      <Form.Control
                        size="sm"
                        autoFocus
                        type="text"
                        // title={`It should start from  ${ctx.lastTo + 1} `}
                        placeholder="Starting"
                        maxLength={7}
                        name="From"
                        defaultValue={ctx.lastTo || 0}
                        onChange={handleUserInput}
                        value={ctx.weightData.From}
                        required
                        // style={{
                        //   border: isInvalid("input5") ? "3px solid red" : "",
                        // }}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="input1">
                      <Form.Label>InsertTo*</Form.Label>
                      <Form.Control
                        size="sm"
                        type="text"
                        autoFocus
                        placeholder="Ending"
                        maxLength={7}
                        name="To"
                        onChange={handleUserInput}
                        value={ctx.weightData.To}
                        required
                        style={{
                          border: isInvalid("input5")
                            ? "3px solid red"
                            : "2px solid black",
                        }}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                </>
              )}

              <Col>
                <Form.Label>BoxType</Form.Label>
                <Form.Select
                  id="dropdown"
                  autoFocus
                  size="sm"
                  style={{ width: "225px", border: "2px solid #000" }}
                  // value={selectedOption}
                  value={
                    ctx.weightData.BoxType
                      ? boxType.findIndex(
                          (bt) => bt.type === ctx.weightData.BoxType
                        )
                      : ""
                  }
                  onChange={
                    handleBoxTypeChange
                    //   if (e.target.value !== null && boxType[e.target.value]) {
                    //     ctx.setWeightData({
                    //       numOfBoxes: ctx.weightData.numOfBoxes,

                    //       NetWt: boxType[e.target.value].NetWt,

                    //       GrossWt: boxType[e.target.value].GrossWt,

                    //       Length: boxType[e.target.value].Length,

                    //       Width: boxType[e.target.value].Width,

                    //       Height: boxType[e.target.value].Height,

                    //       BoxType: boxType[e.target.value].type,

                    //       ShipNo:
                    //         boxType[e.target.value].ShipNo ||
                    //         ctx.weightData.ShipNo,

                    //       From: ctx.weightData.From,
                    //       To: ctx.weightData.To,
                    //     });
                    //   }
                  }
                >
                  <option key={"select"} value={""}>
                    Select
                  </option>

                  {boxType &&
                    boxType.map((bt, i) => {
                      return (
                        <option key={bt.id} value={i}>
                          {bt.type}
                        </option>
                      );
                    })}
                </Form.Select>
              </Col>
              <Col>
                <Form.Group controlId="input4">
                  <Form.Label>Length(cm)*</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    autoFocus
                    name="Length"
                    autoComplete="off"
                    value={ctx.weightData.Length}
                    placeholder="Length"
                    maxLength={7}
                    required
                    style={{
                      border: isInvalid("input5") ? "3px solid red" : "",
                    }}
                    onChange={handleUserInput}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="input5">
                  <Form.Label>Width(cm)*</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    autoFocus
                    name="Width"
                    autoComplete="off"
                    value={ctx.weightData.Width}
                    placeholder="Width"
                    maxLength={7}
                    required
                    style={{
                      border: isInvalid("input5") ? "3px solid red" : "",
                    }}
                    onChange={handleUserInput}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="input5">
                  <Form.Label>Height(cm)*</Form.Label>
                  <Form.Control
                    size="sm"
                    autoFocus
                    type="text"
                    name="Height"
                    autoComplete="off"
                    value={ctx.weightData.Height}
                    placeholder="Height"
                    maxLength={7}
                    required
                    style={{
                      border: isInvalid("input5") ? "3px solid red" : "",
                    }}
                    onChange={handleUserInput}
                  />
                </Form.Group>
              </Col>
              <Col className="ms-2">
                <Form.Group controlId="input2">
                  <Form.Label>NetWt(kg/gm)*</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    autoFocus
                    name="NetWt"
                    autoComplete="off"
                    value={ctx.weightData.NetWt}
                    placeholder="NetWt"
                    maxLength={7}
                    required
                    style={{
                      border: isInvalid("input5") ? "3px solid red" : "",
                    }}
                    onChange={handleUserInput}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="input3">
                  <Form.Label>GrossWt(kg/gm)*</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    autoFocus
                    name="GrossWt"
                    autoComplete="off"
                    value={ctx.weightData.GrossWt}
                    placeholder="GrossWt"
                    maxLength={7}
                    required
                    style={{
                      border: isInvalid("input5") ? "3px solid red" : "",
                    }}
                    onChange={handleUserInput}
                  />
                </Form.Group>
              </Col>

              <Col>
                <Form.Group controlId="input5">
                  <Form.Label>Box Group</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    autoFocus
                    name="BoxGroup"
                    autoComplete="off"
                    value={ctx.weightData.BoxGroup}
                    placeholder="20 bytes"
                    maxLength={20}
                    style={{
                      border: isInvalid("input5") ? "3px solid red" : "",
                      width: "170px",
                    }}
                    onChange={handleInputChange}
                    disabled
                  />
                </Form.Group>
                <div className="dropdown">
                  {boxGroupSuggestion.map((suggestion, index) => (
                    <div
                      className="dropdown-row"
                      key={index}
                      onClick={() => handleSuggestionClick(suggestion)}
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              </Col>

              <Col className="mt-4 pt-2">
                <Button
                  variant="success"
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();

                    initModal();
                  }}
                  className="btn-sm"
                >
                  Process
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      ) : null}
    </>
  );
};

export default Weights;

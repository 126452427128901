/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
// import Header from "../../components/header";
import Table from "./Table";
import context from "../../context/context";
// import Headroom from "react-headroom";
//import FilterFields from "./Filterfields";
import { toast } from "react-hot-toast";
import ErrosToast from "../../components/ErrosToast";

function Orders(props) {
  const navigate = useNavigate();
  // const location = useLocation();
  // const queryParams = new URLSearchParams(location.search);
  // const PO = queryParams.get('PO')
  const ctx = useContext(context);
  useEffect(() => {
    //console.log(ctx.poData.length)
    if (ctx.poData.length === 0) {
      toast.remove();
      ErrosToast("Please select POs for create entry")
      // toast.error("Please select POs  for create entry");
      return navigate("/DisplayPurchase");
    }

    // if(PO){
    // ctx.setIsFilter(true);
    // ctx.setFilterBody({
    //   PO: PO,
    //   SEA: queryParams.get('SEA'),
    //   STY: queryParams.get('STY'),
    //   LOT: queryParams.get('LOT'),
    //   CLR: queryParams.get('CLR'),
    // })
    // ctx.getFilteredPoData({
    //   PO: PO,
    //   SEA: queryParams.get('SEA'),
    //   STY: queryParams.get('STY'),
    //   LOT: queryParams.get('LOT'),
    //   CLR: queryParams.get('CLR'),
    // },'po')
    // }
  }, []);
  return (
    <div>
      {/* <Headroom
        downTolerance={1}
        style={{
          WebkitTransition: "all .5s ease-in-out",
          MozTransition: "all .5s ease-in-out",
          OTransition: "all .5s ease-in-out",
          transition: "all .5s ease-in-out",
        }}
      >
        <Header />
      </Headroom> */}
      
      <Table />
      
    </div>
  );
}

export default Orders;
